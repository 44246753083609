import { ThemeProvider } from "@emotion/react"
import { CssBaseline } from "@mui/material"
import { interconnectaTheme } from "./"

export const AppTheme = ({children}) => {
  return (
    <ThemeProvider theme={interconnectaTheme}>
        <CssBaseline/>

        {children}
    </ThemeProvider>
  )
}
