import { createSlice } from "@reduxjs/toolkit";

export const noteSlice = createSlice({
  name: "note",
  initialState: {
    isSaving: false,
    messageSaved: "",
    notes: [],
    active: null,
    allNotes: [],
    note: "",
    foundNotes: [],
    openModal: false,
    isLoadingLastNotes: false,
    isLoadingAllNotes: false,
    customers:[],
    projectIDToAssociate:"",
    projectNameToAssociate:"",
    suggetionTags:[],
    associatedTags:[]
  },
  reducers: {
    savingNewNote: (state) => {
      state.isSaving = true;
    },
    addNewEmptyNote: (state, action) => {
      console.log("action", action.payload);
      state.notes.unshift({
        id: action.payload.id,
        body: action.payload.body.replace(/(<([^>]+)>)/gi, ""),
        added_by: action.payload.added_by,
        date: action.payload.date,
        email_user: action.payload.email_user,
        title: action.payload.title,
      });
      state.isSaving = false;
    },
    setActiveNote: (state, action) => {
      state.active = action.payload;
    },
    setNotes: (state, action) => {
      state.notes = action.payload;
      state.isLoadingLastNotes = false;
    },
    setNote: (state, action) => {
      state.note = action.payload;
    },
    setAllNotes: (state, action) => {
      state.allNotes = action.payload;
      state.isLoadingAllNotes = false;
    },
    setFoundNotes: (state, action) => {
      state.openModal = true;
      state.foundNotes = action.payload;
    },
    setOpenModalStatus: (state, action) => {
      state.openModal = action.payload;
    },
    setSaving: (state) => {},
    setLoading: (state, action) => {
      if (action.payload.type === "LastNotes") {
        state.isLoadingLastNotes = action.payload.status;
      }
      if (action.payload.type === "AllNotes") {
        state.isLoadingAllNotes = action.payload.status;
      }
    },
    setSavedTags: (state, action) => {
      state.suggetionTags = action.payload
    },
    setCustomerSlice: (state,action) => {
      state.customers = action.payload
    },
    setProjectState: (state, action) => {
      state.projectIDToAssociate = action.payload.id
      state.projectNameToAssociate = action.payload.name
    },
    setTagsState: (state, action) => {
      // state.associatedTags = action.payload.tags
    },
    updateNote: (state, action) => {},
    deleteNote: (state, action) => {},
  },
});
// Action creators are generated for each case reducer function
export const {
  addNewEmptyNote,
  setActiveNote,
  setNotes,
  setSaving,
  updateNote,
  deleteNote,
  savingNewNote,
  setAllNotes,
  setNote,
  setFoundNotes,
  setOpenModalStatus,
  setLoading,
  setCustomerSlice,
  setProjectState,
  setSavedTags
} = noteSlice.actions;
