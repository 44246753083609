import {
  Card,
  CardActions,
  CardContent,
  Button,
  Grid,
  Typography,
  Rating,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import Link from "@mui/material/Link";
import { NoteBody } from "./components/NoteBody";
import { NoteTitle } from "./components/NoteTitle";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const ViewTheNote = () => {
  const { note } = useSelector((state) => state.note);
  const { title, body, email_user, date } = note;
  const username = email_user ? email_user.split("@")[0].toUpperCase() : "";
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 1 }}
      className="animate__animated animate__fadeIn"
    >
        {/* <Button  startIcon={<ArrowBackIosIcon fontSize="small" />} sx={{mb:2, fontSize:'0.8em'}} size="small">
  Go Back
</Button> */}
      
      <NoteTitle title={title} date={date} username={username} />
      <NoteBody body={body} />
    </Grid>
  );
};
