import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Divider } from '@mui/material';

export default function LastNotesSkeleton() {
  return (
    <Box sx={{ width: "100%" }}>
      <Skeleton animation="wave"  height={100}/>
       <Divider variant="inset" /> 
      
      <Skeleton animation="wave"  height={100} />
      <Divider variant="inset" /> 

      <Skeleton animation="wave"  height={100} />
    </Box>
  );
}
