// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwiqPX2AmMGd6KMqXTTUYxSp-B8W-pUy8",
  authDomain: "ic-internal-documentation.firebaseapp.com",
  projectId: "ic-internal-documentation",
  storageBucket: "ic-internal-documentation.appspot.com",
  messagingSenderId: "881049543741",
  appId: "1:881049543741:web:4fc1cc501112b0931df654",
  measurementId: "G-SWXXQEM5JN"
};

// Initialize Firebase

export const FirebaseApp  = initializeApp(firebaseConfig);
export const FirebaseAuth = getAuth( FirebaseApp );
export const FirebaseDB   = getFirestore( FirebaseApp );
const analytics = getAnalytics(FirebaseApp);