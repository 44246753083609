import {
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Rating,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import * as React from "react";
import { Link, Link as RouterLink } from "react-router-dom";
import { collection, onSnapshot } from "firebase/firestore";
import { FirebaseDB } from "../../firebase/config";
import { setLoading } from "../../store/notes";
import { appUrl } from "../../config/config";


export const AllNotesView = () => {
  const collectionRef = collection(FirebaseDB,`all-users/comments/notes`);

  const [allNotes,setAllNotes] = React.useState([])
  const dispatch = useDispatch();

  React.useEffect(() => {
    const unsubscribe = onSnapshot(collectionRef,snapshot => {
      dispatch(setLoading({type:"AllNotes",status:true}))
      const notes = []
      snapshot.docs.forEach(doc =>{
           notes.push({id: doc.id,body:doc.data().body.replace( /(<([^>]+)>)/ig, ''),added_by:doc.data().added_by,date:doc.data().date,email_user:doc.data().email_user,title:doc.data().title})
      })
      function compare( a, b ) {
        if ( a.date > b.date ){
          return -1;
        }
        if ( a.date < b.date ){
          return 1;
        }
        return 0;
      }
      
      setAllNotes(notes.sort(compare))
      dispatch(setLoading({type:"AllNotes",status:false}))

      
    })
    return () => {
      unsubscribe()
    }
  }, [])
  
  
  

  return (
    <List sx={{ width: "100%", maxWidth: 1260, bgcolor: "background.paper" }}>
      {allNotes.map((note, index) => (
        <React.Fragment key={note.id}>
          {
            <ListItem
              alignItems="flex-start"
              secondaryAction={
                <Button variant="text" size="small">
                  Read more
                </Button>
              }
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#03CD5C" }}>
                  {note.email_user
                    ? note.email_user
                        .split("@")[0]
                        .substring(0, 2)
                        .toUpperCase()
                    : "NN"}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    noWrap
                    component={"span"}
                  >
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to={appUrl(`/notes/${note.id}`)}
                      underline="none"
                    >
                      {note.title ? note.title : "No Title"}
                    </Link>
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "1rem" }}
                      noWrap
                      component={"span"}
                    >
                      {note.body.slice(0, 100)}...
                    </Typography>
                    <br />
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "0.7rem", opacity: 0.7, mt: "10px" }}
                      noWrap
                      component={"span"}
                    >
                      Author:{" "}
                      {note.email_user
                        ? note.email_user.split("@")[0].toUpperCase()
                        : "John Doe"}{" "}
                      |{" "}
                      <Rating
                        sx={{ fontSize: "0.7rem", pt: "5px" }}
                        name="size-small"
                        defaultValue={0}
                        size="small"
                      />{" "}
                      | Created at: {new Date(note.date).toLocaleString()} |
                      Customer:
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
         }

          {index < 10 ? <Divider variant="inset" component="li" /> : ""}
        </React.Fragment>
      ))}
    </List>
  );
};
