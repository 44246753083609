import {
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";

export const NoteBody = (props) => {
  const modules = {
    toolbar: [],
  };
  return (
        <Typography variant="h5" component="div" sx={{width: "100%", height:'1000px', mt:0}}>
          <Grid item component="div" elevation={2}>
            <ReactQuill  modules={modules} value={props.body} readOnly={true} theme={"snow"} />
          </Grid>
        </Typography>
  );
};
