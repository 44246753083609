import { useDispatch, useSelector } from "react-redux"
import { Grid, Typography, TextField, Button, Alert } from "@mui/material"
import { useForm } from "../../hooks"
import { loginOrRegister, startLoginWithEmailPassword, startZohoSignIn } from "../../store/auth/thunks";
import { useLocation } from "react-router-dom";
import {  gettingAccessToken } from "../helpers/HandleZohoAuth";
import { checkingCredentials } from "../../store/auth/authSlice";

export const LoginPage = () => {

  

  const {status,errorMessage} = useSelector( state => state.auth)

  const dispatch = useDispatch();
  const {email, password, onInputChange} = useForm({
    email:'',
    password:''
  })

  const onSubmit = (event) => {
    event.preventDefault();
    
    dispatch(startLoginWithEmailPassword({email, password}));
  }
  
  const location = useLocation();

  if(location.hash) {
      dispatch(checkingCredentials());
    const accessToken =  gettingAccessToken(location.hash)
    if(accessToken) {
      dispatch(loginOrRegister({accessToken}))
    }
  }
  
   
    
  const onZohoSignIn = () => {
    dispatch(checkingCredentials());
    /* window.open("https://accounts.zoho.com/oauth/v2/auth?client_id=1000.FFNRUIHYRKZ305WDXXATHI056I22VG&response_type=token&redirect_uri=https://documentationappvfinal-762231134.development.catalystserverless.com/app/auth/login&scope=AaaServer.profile.READ&expires_in=3600000", "_self") */
    window.open("https://accounts.zoho.com/oauth/v2/auth?client_id=1000.FFNRUIHYRKZ305WDXXATHI056I22VG&response_type=token&redirect_uri=https://docs.interconnecta.dev/app/auth/login&scope=AaaServer.profile.READ&expires_in=3600000", "_self")
    /* window.open("https://accounts.zoho.com/oauth/v2/auth?client_id=1000.FFNRUIHYRKZ305WDXXATHI056I22VG&response_type=token&redirect_uri=http://localhost:3000/app/auth/login&scope=AaaServer.profile.READ&expires_in=3600000", "_self") */
    // const timer = setInterval(() => {
    //   if (win.closed) {
    //     clearInterval(timer);
    //     alert('Refresh the page to continue')
    //   }
    // }, 1000);

  
    
    
    // dispatch(checkingAuthentication())
  }

  return (
    <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', backgroundColor: 'primary.main', padding:4 }}
        >
            <Grid item className="box-shadow"
                xs={3}
                sx={{width: {sm:450},backgroundColor: 'white', padding:3, borderRadius:2}}
            >
                <Typography variant="h6" sx={{ mb:1}}>
                    Login
                        <form onSubmit={onSubmit} className='animate__animated animate__fadeIn'>
                            <Grid container>
                                <Grid item xs={12} sx={{mt:2}}>
                                    <TextField
                                      id="email"
                                      label="email"
                                      fullWidth
                                      name="email"
                                      value={email}
                                      onChange={onInputChange}
                                      
                                    />
                                </Grid>
                                 <Grid item xs={12} sx={{mt:2}}>
                                    <TextField
                                      id="password"
                                      label="Password"
                                      fullWidth
                                      name="password"
                                      value={password}
                                      onChange={onInputChange}
                                    />
                                </Grid>
                                <Grid container display={!!errorMessage ? '':'none'} sx={{mt:1}}>
                                    <Grid item xs={12}  >
                                        <Alert severity="error">{ errorMessage }</Alert>
                                    </Grid>

                                </Grid>

                                <Grid container spacing={2} sx={{mb:2, mt:1}}>
                                    <Grid item xs={12} sm={6}>
                                       <Button type="submit" variant="contained" fullWidth>
                                         Login
                                       </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                       <Button variant="contained" color="secondary" fullWidth onClick={onZohoSignIn}>
                                         Login With Zoho
                                       </Button>
                                    </Grid>
                                </Grid>
                              
                            </Grid>
                        </form>

                </Typography>
            </Grid>

        </Grid>
  )
}
