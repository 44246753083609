import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from '../auth/pages/LoginPage'
import { appUrl } from '../config/config'
import { CommentPage } from '../documentation/pages/CommentPage'
import { ViewNotePage } from '../documentation/pages/ViewNotePage'
import { ViewNotesPage } from '../documentation/pages/ViewNotesPage'
import { useCheckAuth } from '../hooks/useCheckAuth'
import { CheckingAuth } from '../ui/'



export const AppRouter = () => {

  const { status } = useCheckAuth()
  if (status === 'checking') {
    return <CheckingAuth />
  }

  return (
    <Routes>

      {
        (status === 'authenticated')
          ? <>
            <Route path={appUrl('/notes')} element={<CommentPage />} />
            <Route path={appUrl('/notes/all')} element={<ViewNotesPage />} />
            <Route path={appUrl('/notes/:id')} element={<ViewNotePage />} />
            <Route path={appUrl('/*')} element={<Navigate to={appUrl('/notes')} />} />
          </>
          : <>
            <Route path={appUrl('/auth/login')} element={<LoginPage />} />
            <Route path={appUrl('/*')} element={<Navigate to={appUrl('/auth/login')} />} />
          </>
      }
      <Route path={appUrl('/*')} element={<Navigate to={appUrl('/auth/login')} />} />

    </Routes>
  )
}
