import { KeyboardArrowRightOutlined, SaveOutlined } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Grid, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { startNewNote } from "../../store/notes";
import { useState, useRef } from "react";
import toolbar from "../components/toolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AdvancedOptions } from "./components/AdvancedOptions";

export const NoteView = () => {
  const date = new Date();
  const currentDate =
    date.toLocaleString("en-US", { month: "long" }) +
    " " +
    date.toLocaleString("en-US", { day: "2-digit" }) +
    ", " +
    date.getFullYear();

  const modules = {
    toolbar: toolbar,
  };
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const { isSaving,projectIDToAssociate,projectNameToAssociate } = useSelector((state) => state.note);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");

  const showAdvancedOptionsF = () => {
    showAdvancedOptions
      ? setShowAdvancedOptions(false)
      : setShowAdvancedOptions(true);
  };
  const handleChange = (event) => {
    setTitle(event.target.value);
  };
  const clearState = () => {
    setTitle("");
    setValue("");
  };
  const saveNote = async (event) => {
    event.preventDefault();
    if(showAdvancedOptions)
    { 
      
      
      
    }
    // throw new Error("q")
    const data = {
      title: title,
      content: value,
      projectId:projectIDToAssociate,
      projectName:projectNameToAssociate
    };

    dispatch(startNewNote(data));
    clearState();
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 1 }}
      className="animate__animated animate__fadeIn"
    >
      <Grid item>
        <Typography fontSize={20} fontWeight="light">
          {currentDate}
        </Typography>
      </Grid>
      <Grid container>
        <TextField
          id="title"
          label="Title"
          sx={{ border: "none", mb: 1, mt: 2, width: { sm: "600px" } }}
          onChange={handleChange}
          value={title}
        />
      </Grid>
      <Grid container>
        <Grid item className="editor" component="div">
          <ReactQuill
            modules={modules}
            theme="snow"
            onChange={setValue}
            placeholder="Content goes here..."
            value={value}
          />
        </Grid>
      </Grid>
        {showAdvancedOptions ? (
          <Grid container sx={{ padding: 2 }}>
            <Grid item>
              <AdvancedOptions />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      <Grid item>
        <Button
          color="primary"
          sx={{ padding: 2 }}
          onClick={saveNote}
          disabled={isSaving}
        >
          <SaveOutlined sx={{ fontSize: 30, mr: 1 }} />
          Save
        </Button>
        <Button
          color={showAdvancedOptions ? "primary" : "secondary"}
          sx={{ padding: 2 }}
          onClick={showAdvancedOptionsF}
        >
          {showAdvancedOptions ? (
            <KeyboardArrowUpIcon sx={{ fontSize: 30, mr: 1 }} />
          ) : (
            <KeyboardArrowRightOutlined sx={{ fontSize: 30, mr: 1 }} />
          )}
          Advanced Options
        </Button>
      </Grid>
    </Grid>
  );
};
