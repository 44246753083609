import { useSelector } from "react-redux"
import AllNotesSkeleton from "../components/skeleton/AllNotesSkeleton"
import { CommentLayout } from "../layout/CommentLayout"
import { AllNotesView } from "../views/AllNotesView"

export const ViewNotesPage = () => {
  const { isLoadingAllNotes } = useSelector((state) => state.note);
  
  return (
    <CommentLayout>
      {isLoadingAllNotes 
        ? <AllNotesSkeleton/>
        : <AllNotesView/>
      }
      
    </CommentLayout>
  )
}
