import {
  Button,
  Card,
  CardActions,
  CardContent,
  Rating,
  Typography,
} from "@mui/material";

export const NoteTitle = (props) => {
  return (
    <Card sx={{ width: "100%" }} elevation={0}>
      <CardContent>
        <Typography variant="h5" component="div">
          {props.title}
        </Typography>
        <Typography sx={{ fontSize: "0.8em" }} color="text.secondary">
          Author: {props.username} |{" "}
          <Rating
       
            sx={{ fontSize: "0.7rem", pt: "5px" }}
            name="size-small"
            defaultValue={0}
            size="small"
          />{" "}
          | Created at: {new Date(props.date).toLocaleString()} |
        </Typography>
      </CardContent>
    </Card>
  );
};
