import { Box, Grid, Skeleton } from "@mui/material";

export const CheckingAuth = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", backgroundColor: "primary.main", padding: 4 }}
    >
      <Grid
        item
        xs={3}
        sx={{
          width: { sm: 450 },
        }}
      >
        {/* For variant="text", adjust the height via font-size */}
        <Box sx={{ pt: 0.5 }}>
          <Skeleton
            variant="circular"
            width={60}
            height={60}
            sx={{ backgroundColor: "white" }}
          />
          <Skeleton
            variant="text"
            sx={{
              fontSize: "3rem",
              mb: 1,
              backgroundColor: "primary.contrastText",
            }}
          />
          <Skeleton
            variant="rectangular"
            fullwidth="true"
            height={60}
            sx={{ mb: 2, backgroundColor: "primary.contrastText" }}
          />
          <Skeleton
            variant="rounded"
            fullwidth="true"

            height={100}
            sx={{ backgroundColor: "primary.contrastText" }}
          />
        </Box>
        {/* For other variants, adjust the size with `width` and `height` */}
        {/* <Skeleton variant="circular" width={40} height={40} />*/}
      </Grid>
    </Grid>
  );
};
