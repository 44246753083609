const axios = require("axios");

export const GetProjects = async (token) => {

    return new Promise((resolve, reject) => {
        var config = {
            method: 'POST',
            url: '/server/api/projects/get-all-projects',
            data:{
                token
            }
          };
        axios(config)
       .then(function (response) {
        resolve(response.data.response.projects)
       })
       .catch(function (error) {
         console.log(error);
        reject(error)
       });
    
      });
    
}
export const GetToken = async () => {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: '/server/api/auth/token',
            
          };
        axios(config)
       .then(function (response) {
         console.log(JSON.stringify(response.data));
        resolve(response.data.response.access_token)
       })
       .catch(function (error) {
         console.log(error);
        reject(error)
       });
    
      });
}