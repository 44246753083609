import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { getNoteById } from '../../store/notes';
import { CommentLayout } from '../layout/CommentLayout';
import { ViewTheNote } from '../views/ViewTheNote';

export const ViewNotePage = (props) => {
    let { id } = useParams(); 
    const dispatch = useDispatch()
    
    
    useEffect(() => {
      dispatch(getNoteById(id))
      
    },[id]);
   
  return (
<CommentLayout>
  <ViewTheNote/>

</CommentLayout>
  
  
  )
}
