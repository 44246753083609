import { collection, getDoc, getDocs, where } from "firebase/firestore";
import { FirebaseApp, FirebaseDB } from "../firebase/config";
// import { query, orderBy, limit } from "firebase/firestore/lite";
import { query, orderBy, limit, endAt, startAt } from "firebase/firestore";
import { doc, onSnapshot } from "firebase/firestore";

export const getNote = async (id) => {
  //   const collectionRef = collection(FirebaseDB,`all-users/comments/notes`);

  const docRef = doc(FirebaseDB, "all-users/comments/notes", id);
  try {
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (error) {
    console.log(error);
  }
};

// Getting last 10 notes
export const loadLast10Notes = async () => {
  const collectionRef = collection(FirebaseDB, `all-users/comments/notes`);
  const q = query(collectionRef, limit(10), orderBy("date", "desc"));
  const docs = await getDocs(q);
  const notes = [];
  docs.forEach((doc) => {
    notes.push({
      id: doc.id,
      body: doc.data().body.replace(/(<([^>]+)>)/gi, ""),
      added_by: doc.data().added_by,
      date: doc.data().date,
      email_user: doc.data().email_user,
      title: doc.data().title,
    });
  });
  return notes;
};

export const loadLast100Tags = async () => {
  const collectionRef = collection(FirebaseDB, `all-users/comments/tags`);
  const q = query(collectionRef, limit(100), orderBy("date", "desc"));
  const docs = await getDocs(q);
  const suggestionsTags = [];
  docs.forEach((doc) => {
    suggestionsTags.push({
      id: doc.id,
      name: doc.data().name,
      added_by: doc.data().added_by,
      date: doc.data().date     
    });
  });
  return suggestionsTags;
}

export const findNotes = async (q) => {
  const collectionRef = collection(FirebaseDB, `all-users/comments/notes`);
  const _query = query(
    collectionRef,
    where("title_keywords", "array-contains-any", q.toLowerCase().split(" "))
  );

  const querySnapshot = await getDocs(_query);
  const notes = [];
  querySnapshot.forEach((doc) => {
    console.log(12);
    notes.push({
      id: doc.id,
      body: doc.data().body.replace(/(<([^>]+)>)/gi, ""),
      added_by: doc.data().added_by,
      date: doc.data().date,
      email_user: doc.data().email_user,
      title: doc.data().title,
    });
    //  console.log(doc.id, " => ", doc.data());
  });
  return notes;
};
