import { doc, collection, setDoc } from "firebase/firestore";
import { FirebaseDB } from "../../firebase/config";
import { loadNotes, loadLast10Notes, loadLast100Tags, getNote, findNotes, GetToken, GetProjects } from "../../helpers";
import {
  addNewEmptyNote,
  setActiveNote,
  savingNewNote,
  setNotes,
  setAllNotes,
  setNote,
  setFoundNotes,
  setLoading,
  setCustomerSlice,
  setSavedTags
} from "./noteSlice";

const createKeywords = (title) => {
  const arrName = [];
  let curName = "";
  title.split("").forEach((letter) => {
    curName += letter;
    arrName.push(curName);
  });
  return arrName;
};
const generateKeywords = (title) => {
  const separatedWords = [];
  title.split(" ").forEach((word) => {
    separatedWords.push(word);
  });
  const keyw = [];
  separatedWords.forEach((element) => {
    keyw.push(createKeywords(element.toLowerCase()));
  });
  return keyw.flat();
};

export const startNewNote = (data) => {
  return async (dispatch, getState) => {
    dispatch(savingNewNote());

    const { uid, email } = getState().auth;
    const newNote = {
      added_by: uid,
      email_user: email,
      title: data.title,
      body: data.content,
      associatedProjectID:data.projectId || "",
      associatedProjectName:data.projectName || "",
      tags:data.tags || "",
      title_keywords: generateKeywords(data.title),
      date: new Date().getTime(),
    };
    const newDoc = doc(collection(FirebaseDB, `all-users/comments/notes`));
     await setDoc(newDoc, newNote);

    newNote.id = newDoc.id;

    dispatch(addNewEmptyNote(newNote));
  };
};

export const startLoadingNotes = () => {
  return async (dispatch, getState) => {
    const { uid } = getState().auth;
    if (!uid) throw new Error("UID is not valid");
    dispatch(setLoading({ type: "LastNotes", status: true }));
    const notes = await loadLast10Notes();
    dispatch(setNotes(notes));
  };
};

export const getSavedTags = () => {
  return async (dispatch, getState) => {
    const { uid } = getState().auth;
    if (!uid) throw new Error("UID is not valid");
    const tags = await loadLast100Tags();
    dispatch(setSavedTags(tags));
  };
}

export const getCustomersFromProjects = () => {
  return async (dispatch) => {
    const token = await GetToken()
    const contacts = await GetProjects(token)
    console.log("contacts",contacts)
    dispatch(setCustomerSlice(contacts));
    
  }
};

export const getNoteById = (id) => {
  return async (dispatch) => {
    const note = await getNote(id);
    dispatch(setNote(note));
  };
};

export const saveNewTag = (data) => {
  return async (dispatch, getState) => {
    const { uid, email } = getState().auth;
    const newTag = {
      added_by: uid,
      email_user: email,
      name: data.name,
      title_keywords: generateKeywords(data.name),
      date: new Date().getTime(),
    };
    const newDoc = doc(collection(FirebaseDB, `all-users/comments/tags`));
     await setDoc(newDoc, newTag);
  };
}

export const findNotesByQuery = (q) => {
  return async (dispatch) => {
    const notes = await findNotes(q);
    dispatch(setFoundNotes(notes));
  };
};
