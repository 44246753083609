import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ListFoundNotes } from "./ListFoundNotes";
import { useDispatch, useSelector } from "react-redux";
import { setOpenModalStatus } from "../../store/notes/noteSlice";

export default function ModalFoundNotes() {
  const dispatch = useDispatch();
  const { openModal } = useSelector((state) => state.note);
  const [open, setOpen] = React.useState(openModal);
  const [scroll, setScroll] = React.useState("paper");

  React.useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const handleClose = () => {

    setOpen(false);
    dispatch(setOpenModalStatus(false));
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Notes</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            component={"span"}
          >
            <ListFoundNotes />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
