import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getCustomersFromProjects, setProjectState, getSavedTags, saveNewTag } from '../../../store/notes';
import { useEffect } from 'react';
import ReactTags from 'react-tag-autocomplete'
import { useCallback } from 'react';


export const AdvancedOptions = () => {

  

  const dispatch = useDispatch();

  const [tags, setTags] = useState([])

  const reactTags = React.useRef()

  const onDelete = useCallback((tagIndex) => {
    setTags(tags.filter((_, i) => i !== tagIndex))
  }, [tags])

  const {customers,suggetionTags} = useSelector((state)=>state.note)

  const onAddition = useCallback((newTag) => {
    var tagsArray = []
    console.log(tagsArray.length)
    console.log(suggetionTags)
    suggetionTags.forEach(element => {
      if(element.name.toLowerCase() !== newTag.name.toLowerCase())
      {
        tagsArray.push(element.name)
      }
    });

    if(tagsArray.length !== 0){
      setTags([...tags, newTag])
    }else{
      dispatch(saveNewTag(newTag))
      dispatch(getSavedTags());
      setTags([...tags, newTag])
    }
  }, [tags])



  
  useEffect(() => {
    dispatch(getCustomersFromProjects());
    dispatch(getSavedTags());
  }, [])
  const setProject = (event,values) => {
    dispatch(setProjectState({id:values.id, name:values.name}))
  }
  const onValidate = useCallback((newTag) => {
    return /^[a-z]{3,12}$/i.test(newTag.name)
  })
  
  return (
    <>
    <ReactTags
      allowNew
      newTagText='Create or Select the tag:'
      ref={reactTags}
      tags={tags}
      suggestions={suggetionTags}
      onDelete={onDelete}
      onAddition={onAddition}
      onValidate={onValidate}
    />
     <p style={{ margin: '0.25rem 0', color: 'gray' }}>
      <small><em>Tags must be 3–12 characters in length and only contain the letters A-Z</em></small>
    </p>
    <p><b>Output:</b></p>
    <pre><code>{JSON.stringify(tags, null, 2)}</code></pre>
    <Autocomplete
      loading
      id="country-select-demo"
      sx={{ width: 300 }}
      onChange={setProject}
      options={customers}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://source.unsplash.com/random/?tech,technology,interconnect`}
            
            alt=""
          />
          {option.name} 
          
        </Box>
      )}
      renderInput={(params) => (
        <TextField
        {...params}
        label="Choose a Customer"
        inputProps={{
          ...params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
        />
        )}
        />
        </>
  );
}

