import { checkingCredentials, logout, login } from "./authSlice";
import {
  loginWithEmailPassword,
  logoutFirebase,
  registerUserWithEmailPassword,
} from "../../firebase/providers";
import { FirebaseAuth } from "../../firebase/config";
import { getProfile } from "../../auth/helpers/HandleZohoAuth";

export const checkingAuthentication = (email, password) => {
  return async (dispatch) => {
    dispatch(checkingCredentials());
  };
};

export const startZohoSignIn = () => {
  return async (dispatch) => {
    dispatch(checkingCredentials());
  };
};

export const loginOrRegister = ({ accessToken }) => {
  return async (dispatch) => {
    const gettingProfile = await getProfile(accessToken);
    if (gettingProfile) {
      console.log("got profile", gettingProfile);
      //checking if domain=interconnecta.com
      if (gettingProfile.success) {
        if (gettingProfile.response.Email.split('@')[1] !== "interconnecta.com") {
          alert('Your Email is not valid. Please contact your administrator');
          window.open("https://interconnecta.com", "_self")

          throw new Error("Not valid domain email address")
        }
        const result = await loginWithEmailPassword({
          email: gettingProfile.response.Email,
          password:
            gettingProfile.response.ZUID + gettingProfile.response.Email,
          displayName: gettingProfile.response.Display_Name,
        });
        if (!result.ok) {
          console.log("error trying to login with loginWithEmailPassword", result)
          if (result.errorMessage === 'Firebase: Error (auth/wrong-password).') {
            console.log('usuario existe y se creo con contraseña y correo')
            alert("Error (auth/wrong-password).")
            /* window.open("https://documentationappvfinal-762231134.development.catalystserverless.com/app/auth/login", "_self") */
            window.open("https://docs.interconnecta.dev/app/auth/login", "_self")
            /* window.open("http://localhost:3000/app/auth/login", "_self") */

            throw new Error("error when trying to logon - Firebase: Error (auth/wrong-password).")
            //usuario existe y se creo con contraseña y correo
          } else {
            const loginWithProfile = await registerUserWithEmailPassword({
              email: gettingProfile.response.Email,
              password:
                gettingProfile.response.ZUID + gettingProfile.response.Email,
              displayName: gettingProfile.response.Display_Name,
            });
            if (!loginWithProfile.ok) {
              console.log("error when trying to create registerUserWithEmailPassword", loginWithProfile)
              alert("Error " + loginWithProfile.errorMessage)
              /* window.open("https://documentationappvfinal-762231134.development.catalystserverless.com/app/auth/login", "_self") */
              /* window.open("http://localhost:3000/app/auth/login", "_self") */
              window.open("https://docs.interconnecta.dev/app/auth/login", "_self")
              throw new Error("error when trying to create registerUserWithEmailPassword")
            }
          }
          //else {

          //     dispatch(
          //       startLoginWithEmailPassword({
          //         email: gettingProfile.res.Email,
          //         password: gettingProfile.res.ZUID + gettingProfile.res.Email,
          //       })
          //     );
          //   }
        } else {

          dispatch(login(result));
        }
      } else {
        alert(
          "An error has occurred. Please contact support. Error: " +
          gettingProfile.res
        );
      }
    }
  };
};

export const startLoginWithEmailPassword = ({ email, password }) => {
  return async (dispatch) => {
    dispatch(checkingCredentials());
    const result = await loginWithEmailPassword({ email, password });
    if (!result.ok) return dispatch(logout(result));
    dispatch(login(result));
  };
};

export const startLogout = () => {
  return async (dispatch) => {
    await logoutFirebase();
    dispatch(logout({}));
  };
};
