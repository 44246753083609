import { useDispatch, useSelector } from "react-redux"
import { onAuthStateChanged } from 'firebase/auth';
import { login, logout } from '../store/auth/authSlice'
import {useEffect} from 'react'
import { FirebaseAuth } from '../firebase/config'
import { startLoadingNotes } from "../store/notes";


export const useCheckAuth = () => {
   
  const {status} = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    onAuthStateChanged( FirebaseAuth, async (user) =>{
      if(!user) return dispatch(logout())
      const{uid,displayName,email} = user
      dispatch(login({uid,email}))
      dispatch(startLoadingNotes())

    });
  
    
  }, [])
  return {status}
}
