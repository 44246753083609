import axios from 'axios'

export const gettingAccessToken = (hash) => {
    if(hash.includes('access_token'))
    {
       const urlArray = hash.split('&');
       const foundIndex = urlArray.findIndex(element => element.includes('access_token'));
       if(foundIndex !== -1)
       {
         return urlArray[foundIndex].replace('access_token=','');
       }else{
        return false;
       }
    }
}

export const getProfile = async (token) => {
  
  return new Promise((resolve, reject) => {
         var config = {
             method: 'post',
             url: '/server/api/auth/profile',
             data:{
               token
             }
           };
         axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
         resolve(response.data)
        })
        .catch(function (error) {
          console.log(error);
         reject(error)
        });
       })
}