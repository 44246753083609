import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
// import DirectionsIcon from '@mui/icons-material/Directions';
import {  Tooltip } from "@mui/material";


import { useEffect, useState } from "react";
import ListFoundNotes from "./ModalFoundNotes";
import { useDispatch, useSelector } from "react-redux";
import { findNotesByQuery } from "../../store/notes/thunks";

export default function SearchComponent() {
  const [query, setquery] = useState("");
  const { foundNotes } = useSelector((state) => state.note);

  const dispatch = useDispatch();
  const handleSearch = async (event) => {
    setquery(event.target.value);
  };
  useEffect(() => {
    if (foundNotes.length > 0) {
      setquery("");
    }
  }, [foundNotes]);

  const StartToFindNotes = async () => {
    dispatch(findNotesByQuery(query));
  };

  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "50%" }}
    >
      <Tooltip title="Advanced Search">
        <IconButton sx={{ p: "10px" }} aria-label="menu">
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        value={query}
        onChange={handleSearch}
        inputProps={{ "aria-label": "search google maps" }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            StartToFindNotes();
          }
        }}
      />
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={StartToFindNotes}
      >
        <SearchIcon />
      </IconButton>
      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
      <ListFoundNotes />
    </Paper>
  );
}
