import { Typography, Divider } from "@mui/material"
import { useSelector } from "react-redux"
import LastNotesSkeleton from "../components/skeleton/LastNotesSkeleton"
import { CommentLayout } from "../layout/CommentLayout"
import { LastNotes, NoteView, NothingSelectedView } from "../views"


export const CommentPage = () => {

  const { isLoadingLastNotes } = useSelector((state) => state.note);
  

  return (
    <CommentLayout>
       {/* <NothingSelectedView/>  */}
       <NoteView/> 
       <Divider sx={{mb:2, mt:2}}/>
       <Typography variant="5" color="initial">Last Notes</Typography>
       {
        isLoadingLastNotes 
        ? <LastNotesSkeleton/>
        : <LastNotes/>
       }

    </CommentLayout>
  )
}
