import { createTheme } from "@mui/material";
import {red} from '@mui/material/colors'

export const interconnectaTheme = createTheme({
    palette: {
        primary: {
            main:'#03CD5C',
            contrastText:'white'
        },
        secondary: {
            main: '#3C434D',
            contrastText:'#fff'
        },
        error: {
            main: red.A400
        }
    }
})