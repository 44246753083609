import * as React from "react";
import { useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { MenuOutlined } from "@mui/icons-material";
import { Link, Link as RouterLink } from "react-router-dom";

import { Button, Grid, Rating, Typography } from "@mui/material";
import { appUrl } from "../../config/config";

export const LastNotes = () => {
  const { notes } = useSelector((state) => state.note);
  const { email } = useSelector((state) => state.auth);
  let _display;
  return (
    <List sx={{ width: "100%", maxWidth: 1260, bgcolor: "background.paper" }}>
      {notes.map((note, index) => (
        <React.Fragment key={note.id}>
          {index < 10 ? (
            <ListItem
              alignItems="flex-start"
              secondaryAction={
                <Button variant="text" size="small" sx={{ color: "#03CD5C" }}>
                  <Link
                    component={RouterLink}
                    color="primary"
                    to={appUrl(`/notes/${note.id}`)}
                    underline="none"
                    sx={{ color: "#03CD5C" }}
                  >
                    <Typography component="span" sx={{ color: "#03CD5C" }}>
                      Read more
                    </Typography>
                  </Link>
                </Button>
              }
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#03CD5C" }}>
                  {note.email_user
                    ? note.email_user
                        .split("@")[0]
                        .substring(0, 2)
                        .toUpperCase()
                    : "NN"}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    noWrap
                    component={"span"}
                  >
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to={appUrl(`/notes/${note.id}`)}
                      underline="none"
                    >
                      {note.title ? note.title : "No Title"}
                    </Link>
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "1rem" }}
                      noWrap
                      component={"span"}
                    >
                      {note.body.slice(0, 100)}...
                    </Typography>
                    <br />
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "0.7rem", opacity: 0.7, mt: "10px" }}
                      noWrap
                      component={"span"}
                    >
                      Author:{" "}
                      {note.email_user
                        ? note.email_user.split("@")[0].toUpperCase()
                        : "John Doe"}{" "}
                      |{" "}
                      <Rating
                        sx={{ fontSize: "0.7rem", pt: "5px" }}
                        name="size-small"
                        defaultValue={0}
                        size="small"
                      />{" "}
                      | Created at: {new Date(note.date).toLocaleString()} |
                      Customer:
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ) : (
            ""
          )}

          {index < 10 ? <Divider variant="inset" component="li" /> : ""}
        </React.Fragment>
      ))}
    </List>
  );
};
